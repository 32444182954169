import React from 'react';

const RoundDollar: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M13 4V20H11V4H13Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3321 5.49306C12.9743 5.37538 14.8534 5.79117 15.8567 6.34266L16.733 6.82435L15.7697 8.57703L14.8933 8.09534C14.2756 7.75582 12.7912 7.39362 11.4751 7.48794C10.8254 7.5345 10.3421 7.68505 10.0473 7.89288C9.80618 8.06288 9.648 8.29338 9.648 8.732C9.648 9.28335 9.85959 9.60954 10.2524 9.89759C10.713 10.2353 11.3701 10.4762 12.2062 10.7548C12.2647 10.7743 12.3244 10.7941 12.385 10.8141C13.1117 11.0548 13.9784 11.3418 14.6606 11.7814C15.4704 12.3032 16.132 13.1117 16.132 14.351C16.132 15.3986 15.7814 16.2674 15.1149 16.8974C14.475 17.5023 13.6376 17.7988 12.8085 17.9231C11.1655 18.1694 9.20531 17.7941 7.66056 17.2366L6.71993 16.8972L7.39881 15.0159L8.33944 15.3554C9.71269 15.8509 11.3185 16.1241 12.512 15.9452C13.1012 15.8568 13.4996 15.6722 13.741 15.444C13.9559 15.2409 14.132 14.9244 14.132 14.351C14.132 13.9693 13.9831 13.7241 13.5772 13.4626C13.1175 13.1663 12.4825 12.9549 11.6652 12.6827C11.635 12.6726 11.6045 12.6624 11.5738 12.6522C10.7889 12.3906 9.825 12.0642 9.06981 11.5105C8.24691 10.9071 7.648 10.0177 7.648 8.732C7.648 7.63612 8.12807 6.79887 8.89496 6.25824C9.60817 5.75545 10.5028 5.5525 11.3321 5.49306Z"
      />
    </svg>
  );
};

export default RoundDollar;
